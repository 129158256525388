




















import { Fields } from 'src/types/interface';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, NodeData } from '@contentful/rich-text-types';
import { SectionsIds } from 'src/types/interface';
import Vue from 'vue';

// import SvgIcon from '../components/SvgIcon.vue';
// import CurrentChallenges from '../components/icons/CurrentChallenges.vue';
// import Blog from '../components/icons/Blog.vue';
// import Hobbies from '../components/icons/Hobbies.vue';
// import { createClient } from '@/plugins/contentful.js';
// import * as contentful from 'contentful';

// const client = createClient();
import { IHomeFields } from '../../@types/generated/contentful';
const cachedSections = [
  {
    name: 'Few words about me',
    contents:
      "An experienced technology leader with a passion for innovation and results.I've successfully led teams to deliver exceptional digital experiences by fostering a collaborative and high-performing culture. With a deep understanding of UI/UX, Web performance, and software engineering, I excel at identifying and solving complex technical challenges, ensuring that our projects are always on track and meet the highest standards. I am committed to staying up-to-date with the latest technologies and industry trends to drive continuous improvement and deliver innovative solutions.",
  },
  {
    name: 'Posts',
    contents:
      'My latest posts are: Service worker caching strategies; Progressive Web App in 2021; Wrap any rest API with GraphQL from the frontend; Save money and time with a headless CMS',
  },
  {
    name: 'Hobbies',
    contents:
      "I enjoy spending quality time with my sons, often engaging in exciting video game adventures together. I stay updated with the latest tech trends by reading my Twitter and Dev.to feeds whenever I have a few moments to spare. I'm passionate about exploring new ideas and concepts through hands-on experimentation. This often leads me to dive into various sample projects inspired by my feeds. Additionally, I have a keen interest in drones and love capturing stunning photographs of the world around me.",
  },
];

export default Vue.extend({
  name: 'Home' as string,
  components: {
    // SvgIcon,
    // CurrentChallenges,
    // Blog,
    // Hobbies,
  },
  data() {
    return {
      data: {
        sections: cachedSections as any,
      } as any,
    };
  },
  async created() {
    this.getHomeContent();
  },
  methods: {
    getSectionIds(sectionsArray: any[]) {
      const sectionsIds: any[] = [];
      for (let i = 0; i < sectionsArray.length; i++) {
        const sectionId = sectionsArray[i].sys.id;
        sectionsIds.push(sectionId);
      }
      // console.log(sectionsIds);
      return sectionsIds;
    },
    async getSection(sectionId: string) {
      await this.$contentful.getEntry<IHomeFields>(sectionId).then(data => {
        const options: NodeData = {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: ({
              data: {
                target: { fields },
              },
            }: Fields) =>
              `<img src="${fields.file.url}"
                   height="100%"
                   width="100%"
                   alt="${fields.description}"/>`,
          },
        };
        // Check if the contents are a Rich Text document (which needs conversion) or plain text (string)
        if (typeof data.fields.contents !== 'string') {
          data.fields.contents = documentToHtmlString(
            data.fields.contents,
            options,
          );
        }
        if (data.fields.name === 'Few words about me') {
          this.data.sections.splice(0, 1, data.fields);
        } else if (data.fields.name === 'Posts') {
          this.data.sections.splice(1, 1, data.fields);
        } else if (data.fields.name === 'Hobbies') {
          this.data.sections.splice(2, 1, data.fields);
        }
      });
      // console.log(this.data.sections);
    },
    async getHomeContent() {
      await this.$contentful
        .getEntry<SectionsIds>('6mKEsHdAD6XuHi6eL5PZSA')
        .then(data => {
          return this.getSectionIds(data.fields.sections);
        })
        .then(sectionIds => {
          for (let i = 0; i < sectionIds.length; i++) {
            this.getSection(sectionIds[i]);
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    },
  },
});
